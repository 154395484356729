import React from 'react'
import styles from './ComingSoon.module.scss'
import {Logo} from "../../components/Logo/Logo";

export const ComingSoon = () => {
    return (
        <div className={styles.comingSoonContainer}>
            <Logo transparent={true} className={styles.logo}/>
            <h1>coming soon...</h1>
        </div>
    )
};
