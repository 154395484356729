import React from 'react'
import {Logo} from "../../components/Logo/Logo";
import styles from './JoinMunichGarage.module.scss'

export const JoinMunichGarage = () => {
    return (
        <div className={styles.container}>
            <Logo transparent={true} className={styles.logo}/>
            <div className={styles.rules}>
                <p>Hi !</p>
                <p>Wir freuen uns, dass du ein Teil der Munich Garage werden willst 😀!</p>
                <p>Bitte beachte jedoch, dass es auch bei uns ein paar Regeln gibt, die von jedem Mitglied beachtet
                    werden müssen:</p>
                <ul>
                    <li><p>Wir sind eine Gruppe, in welcher Loyalität und Freundlichkeit höchste Priorität hat!</p>
                    </li>
                    <li><p>Bitte wende dich bei Problemen mit den Mitgliedern sofort an einen der Admins (Chris /
                        Talha)</p>
                    </li>
                    <li><p>Bitte vermeide Spam oder unnötige Bilder / Videos in der WhatsApp Gruppe!</p>
                    </li>
                    <li><p>Frauen in unserer Gruppe sind keine Flirtobjekte und wir sind schließlich keine Datinggruppe,
                        deshalb werden sexuelle Belästigungen / Flirts nicht toleriert!</p>
                    </li>
                    <li><p>Solltest du Ideen oder Veränderungsvorschläge haben, sind wir jederzeit offen dafür! Bitte
                        wende dich bei solchen Vorschlägen ebenfalls an einen der Admins.</p>
                    </li>
                    <li><p>Regelverstöße werden geahndet und führen im schlimmsten Fall zum Ausschluss aus der
                        Gruppe</p>
                    </li>
                </ul>
                <p>Wenn du all diese Regeln akzeptierst, und dich daran halten kannst, freuen wir uns dich als neues
                    Mitglied der Munich Garage begrüßen zu dürfen!</p>
                <p>Bitte trete der WhatsApp-Gruppe über den folgenden Link bei und stelle dich kurz den anderen
                    Mitgliedern wie folgt vor:</p>
                <p>"Hallo ich bin der XY, bin XY Jahre alt und fahre einen XY."</p>
                <p>Gerne darfst du natürlich auch hinzufügen, woher du kommst, oder ob du z.B. Mechaniker bist und bei
                    Problemen rund ums Auto helfen kannst!</p>
                <p>Hier der Link zur WhatsApp-Gruppe: <a
                    href="https://bit.ly/JoinMunichGarage">https://bit.ly/JoinMunichGarage</a></p>
                <p>Wir freuen uns auf eine starke Saison mit dir!</p>
                <strong>Dein Munich-Garage Team</strong>

            </div>
        </div>
    );
};
