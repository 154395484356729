import React from 'react'
import {ComingSoon} from "../../pages/ComingSoon/ComingSoon";
import {JoinMunichGarage} from "../../pages/JoinMunichGarage/JoinMunichGarage";
import { Switch, Route } from "react-router-dom";


export const Router = () => {
    return(
        <Switch>
            <Route exact={true} path="/join" component={JoinMunichGarage} />
            <Route path="/" component={ComingSoon} />
        </Switch>
    )
};
