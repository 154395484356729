import React from 'react';
import {ComingSoon} from "./pages/ComingSoon/ComingSoon";
import { BrowserRouter } from "react-router-dom";
import {Router} from "./components/Router/Router";
import {JoinMunichGarage} from "./pages/JoinMunichGarage/JoinMunichGarage";

function App() {
  return (
      <div>
        <Router />
      </div>
  );
}

export default App;
