import React from 'react'
import NonTransparentLogo from '../../assets/Logo.svg'
import TransparentLogo from '../../assets/Logo_transparent.svg'
type LogoProps = {
    transparent: boolean,
    className: string
}

export const Logo = ({transparent, className} : LogoProps) => {
    return(

        <img className={className} src={transparent ? TransparentLogo : NonTransparentLogo} alt={"Logo"}/>
    )
};
